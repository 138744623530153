<template>
    <div class="login">
        <b-container>
            <b-form class="form" @submit.prevent="onSubmit">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="logo"
                    alt="logo"
                />
                <h4 class="guideline">
                    {{ $t('login.guideline') }}
                </h4>
                <b-form-input
                    v-model="email"
                    type="email"
                    placeholder="E-MAIL"
                    class="input-email"
                />
                <b-form-input
                    v-model="password"
                    type="password"
                    :placeholder="$t('login.passwordPlaceHolder')"
                    class="input-password"
                />
                <div class="wrong-text mt-3">
                    <span v-if="missingEmailPasswordAlert">{{ $t('login.missingEmailPasswordAlert') }}</span>
                    <span v-if="!emailIsValid">{{ $t('login.wrongEmailFormatAlert') }}</span>
                    <span v-if="failedResponse.status === 401 && !missingEmailPasswordAlert && emailIsValid">{{ $t('login.invalidCredentialsAlert') }}</span>
                    <span v-if="failedResponse.status === 400 && !missingEmailPasswordAlert && emailIsValid">Email not active</span>
                </div>
                <b-button
                    type="submit"
                    variant="disable"
                    class="button mb-3"
                >
                    {{ $t('login.loginButton') }}
                </b-button>
                <div class="mt-3">
                    {{ $t('login.clickToRegister') }}
                    <router-link to="/register" class="register-link">
                        {{ $t('login.here') }}
                    </router-link>
                </div>
                <router-link to="/forgot-password" class="forgotPass">
                    {{ $t('login.forgotPasswordText') }}
                </router-link>
            </b-form>
        </b-container>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        name: 'Login',
        mixins: [ config ],
        data() {
            return {
                email: null,
                password: null,
                missingEmailPasswordAlert: false,
                emailIsValid: true,
                failedResponse: {}
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            }
        },
        methods: {
            onSubmit() {
                if (!this.email && !this.password) {
                    this.emailIsValid = true
                    this.missingEmailPasswordAlert = true
                    return
                } else {
                    this.validEmail(this.email)
                    this.missingEmailPasswordAlert = false
                }
                this.$store.dispatch('login', {
                    username: this.email,
                    password: this.password
                }).then(() => {
                    this.$router.push({
                        name: 'home'
                    })
                }).catch((error) => {
                    this.failedResponse = error.response
                })
            },
            validEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                this.emailIsValid = re.test(email)
            }
        }
    }
</script>

<style lang="scss" scoped>
.login {
    padding-top: 80px;
    height: 100%;
    margin: 0 auto;
    min-width: 300px;
    overflow: auto;
    padding-bottom: 2.5rem;
}

.form {
    max-width: 420px;
    padding: 30px 38px 2rem;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid var(--white);
    background-color: var(--login-forms-background);
    color: var(--white);
}

.input-email {
    font-size: 16px;
    height: auto;
    padding: 10px;
    font-weight: 700;
    margin-top: 10%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.input-password {
    font-size: 16px;
    height: auto;
    padding: 10px;
    font-weight: 700;
    margin-top: 3%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.wrong-text {
    font-weight: 700;
    color: var(--form-error-color);
}

.logo {
    margin-top: 10%;
    width: 191px;
    vertical-align: middle;
}

.button {
    padding: 4% 27%;
    white-space: nowrap;
    border-radius: 17px;
    border: 1px solid var(--white);
    background-color: var(--white);
    display: block;
    width: 100%;
    margin-top: 50px;
    color: var(--login-forms-background);
    font-weight: 700;
    font-size: 18px;
}

.button:active {
    color: var(--black);
}

.guideline {
    margin-top: 10%;
}

.forgotPass {
    font-size: 0.9em;
    color: var(--white);
}

.register-link {
    color: var(--white);
    font-weight: 700;
}

</style>
